<template>
  <static-fullscreen-card @sendHeight="setContentHeight">
    <template v-slot:title>({{ data.id }}) {{ data.name }} [{{ statusCurrent.text }}] </template>
    <template v-slot:actions>
      <a-btn-delete v-if="!loading && !showOnly && id && data.status === 0 && getAccess('payOrder.delete')" @click="removeDialogShow = true" />
      <a-btn-status v-if="canStatus" :items="m.statuses" @click="clickStatus($event)" :disabled="!canStatus || loading" :data="data" :status="data.status" />
      <v-btn color="primary" @click="showEditDialog = true" title="Редактировать элемент"><btn-title icon="far fa-edit">Редактировать</btn-title></v-btn></template
    >
    <portal to="v-main">
      <remove-dialog v-model="removeDialogShow" @remove="remove(api, id)" :title="removeTitle" />
      <s-edit-form
        v-model="showEditDialog"
        v-if="showEditDialog"
        :m="m"
        :configForm="{ head: configHead, title: configHead.title, api: url }"
        :id="id"
        :opt="data.status === 0 ? '' : 'readonly'"
      />
      <s-editor-edit-field ref="editField" :values="editField && editField.data" :model="editField && editField.model" />
      <s-editor-editor
        v-if="getAccess('suData')"
        ref="suEdit"
        v-model="suEditShow"
        :config="{ table: 'pay_orders', filter: { id } }"
        @show="suEditShow = true"
        :activate="(id || 0) > 0"
      />
      <s-quick-addDocument v-if="showAddDocDialog" v-model="showAddDocDialog" :id="id" :api="url" :modelDoc="addDocModel" />
      <confirm-dialog ref="confirmDialog" />
    </portal>
    <portal to="v-goup" v-if="idShow">
      <v-btn class="mr-3" fab small @click="$emit('close', data)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
    </portal>
    <v-row>
      <v-col cols="12" sm="6">
        <material-card>
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelLeft" :config="{ dense: true, hideNull: !true }" />
        </material-card>
      </v-col>
      <v-col cols="12" sm="6">
        <material-card>
          <template v-slot:heading> </template>
          <a-form-view :value="data" :model="modelRight" :config="{ dense: true, hideNull: !true }" />
        </material-card>
      </v-col>
      <v-col cols="12">
        <material-card color="third" class="block_detail">
          <template v-slot:heading>
            <v-tabs v-model="tab" background-color="transparent" slider-color="white">
              <v-tab v-for="(tab, i) in tabs" v-if="tab.show" :key="i" class="mr-3" :href="`#tab-${tab.name}`">
                {{ tab.title }}
              </v-tab>
            </v-tabs>
          </template>
          <v-tabs-items v-model="tab" class="transparent" style="min-height: 200px" touchless>
            <v-tab-item v-for="(t, i) in tabs" :key="i" v-if="t.show" :id="`tab-${t.name}`">
              <TabDocs v-if="'docs'.split(',').includes(t.name)" :data="data" :category="showDocs(t.name)" @addDocs="addNewDocs(t.name)" :height="detailHeight" />
              <s-tabs-cash
                v-if="t.name === 'cash' && mCash && parentData"
                :initData="cashInitData"
                :data="parentData"
                :operations="mCash.operationsCash[parentData.type] || []"
                type="full"
                :canAdd="data.status == 1"
                :m="mCash"
                :height="detailHeight"
              />

              <comment-view v-if="t.name == 'comments'" :height="detailHeight" :id="id || 0" :targetName="'payOrder'" :needUpdate="needUpdateComments" />
              <s-tabs-status-history v-if="t.name == 'statusHistory'" :data="data.status_history" :m="m" :height="detailHeight" />
            </v-tab-item>
          </v-tabs-items>
        </material-card>
      </v-col>
    </v-row>
  </static-fullscreen-card>
</template>

<script>
import { autoHeightBlock, getAccess, popupMenu, removeEl, genModel, statusDoc, comments } from "@/components/mixings";
export default {
  mixins: [autoHeightBlock, getAccess, popupMenu, removeEl, genModel, statusDoc, comments],
  components: {
    TabDocs: () => import("./tabs/tabDocs"),
  },
  props: {
    idShow: Number,
    showOnly: { type: Boolean, default: false },
  },
  data() {
    return {
      id: 0,
      loading: true,
      removeDialogShow: false,
      showEditField: false,
      showEditDialog: false,
      addDocModel: null,
      showAddDocDialog: false,
      suEditShow: false,
      editField: null,
      title: "",
      m: this.$store.getters["config/get"].models.payOrder,
      removeTitle: "Вы действительно хотите удалить эту заявку?",
      data: {},
      parentData: {},
      tab: 0,
      tabs: [
        { name: "docs", title: "Документы", show: true },
        { name: "cash", title: "Оплата", show: true },
        { name: "statusHistory", title: "История статусов", show: this.getAccess("payOrder.statusHistory") },
        { name: "comments", title: "Комментарии", show: this.getAccess("payOrder.comments") },
      ],
    };
  },

  created() {
    this.$root.title = this.m.title;

    this.id = this.idShow || Number(this.$route.params.id);
    this.fitchData();
  },
  computed: {
    cashInitData() {
      let docs = this.data.filesData.map((f) => {
        return { file: f.file, name: f.file_name };
      });
      return { files: docs };
    },
    mCash() {
      if (this.data.parent_name === "AccountingDocOrderGoodModel") return this.$store.getters["config/get"].models.documentOrderGoods;
      if (this.data.parent_name === "AccountingDocOrderServiceModel") return this.$store.getters["config/get"].models.documentOrderService;
      return null;
    },
    canStatus() {
      return this.getAccess("payOrder.status");
    },
    configHead() {
      let config = JSON.parse(JSON.stringify(this.m?.config.default || {}));
      if (!this.canStatus) config.fieldsRO += (config.fieldsRO ? "," : "") + "date_start";
      return config || {};
    },
    url() {
      return this.m.api;
    },
    api() {
      return this.m.api;
    },
    modelLeft() {
      let model = this.calcModel("viewForm1");
      return model;
    },
    modelRight() {
      let model = this.calcModel("viewForm2");
      return model;
    },
    model: {
      get() {
        let model = this.calcModelConfig(this.configHead);
        return model;
      },
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.fitchData();
      }
    },
    showAddDocDialog(v) {
      if (!v) {
        this.fitchData();
      }
    },
  },
  methods: {
    async onEditField(e) {
      let model = Object.assign({}, e);
      model.editField = model.name;
      model.name = "field";
      this.editField = { data: { date_end: this.data.date_end }, model: [model] };
      return this.$refs.editField.show(this.editField);
    },
    async beforeChangeStatus(status) {
      if (status.value === 1) {
        let res = await this.onEditField({ name: "date_start", title: "Плановая дата платежа", type: "datestring", validator: ["req"] });
        if (res) {
          this.data[res.name] = res.value;
          return { data: { [res.name]: res.value } };
        } else {
          console.log("no", res);
        }
        return false;
      }
      if (status.value === 2) {
        try {
          let debit = 0;
          let rows_count = 0;
          let params = { acc: "60.2", doc_name: this.data.parent_name, doc_id: this.data.parent_id };

          let resp = await this.$axios.post("/report_sql", { name: "getBalanceByDocument", params });
          if (resp.data.status == "ok") {
            resp.data.data.forEach((el) => {
              debit += el.value;
              rows_count = el.rows_count;
            });
            if (debit === 0 && rows_count > 1) {
              return true;
            } else {
              throw "Документ не оплачен ";
            }
          } else throw "Не удалось проверить оплату документа";
        } catch (error) {
          console.error(error);
          let err = typeof error === "string" ? error : "Ошибка при проверке документа";
          this.$root.$emit("show-info", { type: "error", text: err });
          return false;
        }
      }

      return true;
    },
    afterRemove(data) {
      if (this.idShow) {
        this.$emit("close", this.data);
        return;
      }

      this.removeDialogShow = false;
      this.$router.push({ name: "payOrder" });
    },
    async fitchData() {
      const d = await this.$axios.get(this.url + "/" + this.id);
      if (d.data && d.data.data) {
        this.data = d.data.data;
      }
      this.afterFetch();
    },
    async afterFetch() {
      let api = this.mCash.api;
      this.parentData = this.data.parent;
      this.loading = false;

      return;
      const d = await this.$axios.get(api + "/" + this.data.parent_id);
      if (d.data && d.data.data) {
        this.parentData = d.data.data;
      }
    },
    showDocs(field) {
      return this.model.find((el) => el.name == field)?.category || -1;
    },
    addNewDocs(field) {
      this.addDocModel = this.model.find((el) => el.name == field) || null;
      if (!this.addDocModel) return;
      this.showAddDocDialog = true;
    },
  },
};
</script>
